import React from "react";
import styled from "@emotion/styled";
import { graphql } from "gatsby";

import Layout from "../components/layout/layout";
import PageHeader from "../components/pageHeader";
import BlogList from "../components/blog/BlogList";

import { IBlogDetail } from "../utils/types";

interface Blog {
  data: {
    allFeedMediumBlog: {
      nodes: Array<IBlogDetail>
    },
  }
}

const BlogCTAContainer = styled.div<any>`
  text-align: center;
  max-width: 1035px;
  margin: 0 auto;
  padding: 78px 20px;
`;

const BlogCTATitle = styled.h2<any>`
  font-size: 48px;
  font-weight: normal;
  line-height: 57px;
  color: #fff;
  margin: 0 0 10px 0;
`;

const BlogCTAContent = styled.h3<any>`
  font-size: 33px;
  font-weight: normal;
  color: #fff;
  line-height: 57px;
  margin: 0;
`;

export default function Blog({ data }: Blog): JSX.Element {
  const { nodes } = data.allFeedMediumBlog;

  const BlogFooterCTA = () => {
    return (
      <BlogCTAContainer>
        <BlogCTATitle>Break the Norm.</BlogCTATitle>
        <BlogCTAContent>Subscribe to the Strategica Blog and get high-growth go-to-market strategies sent directly to your inbox.</BlogCTAContent>
      </BlogCTAContainer>
    )
  }

  return (
    <Layout title="Blog" hideContactFooterBanner FooterCTA={BlogFooterCTA}>
      <PageHeader
        title="Blog Articles"
        titleSubtext="Strategica Blog"
        description="High-growth go-to-market strategy for innovative organizations: We help forward-thinking companies launch world-changing products."
      />
      <BlogList nodes={nodes} />
    </Layout>
  )
}

export const query = graphql`
{
  allFeedMediumBlog(sort: {fields: isoDate, order: DESC}) {
    nodes {
      creator
      pubDate
      title
      slug
      content {
        encoded
        encodedSnippet
      }
    }
  }
}
`;