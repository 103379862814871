import React from "react";
import propTypes from "prop-types";
import styled from "@emotion/styled";
import moment from "moment";
import { Link } from "gatsby";

import { IBlogDetail } from "../../utils/types";
import { theme } from "../../utils/theme";
import { md, lg } from "../../utils/breakpoints";

const CardContainer = styled.div<any>`
  margin-bottom: 60px;
  width: 100%;
  
  @media (min-width: ${md}px) {
    width: 48%;
  }

  @media (min-width: ${lg}px) {
    max-width: 360px;
    width: 30%;
  }
`;

const CardLink = styled(Link)`
  text-decoration: none;
`;

const CardImage = styled.img<any>`
  border: 1px solid #ccc;
  margin-bottom: 20px;
  height: 192px;
  width: 100%;
  object-fit: cover;
`;

const CardTitle = styled.h2<any>`
  font-family: ${theme.headerFontFamily};
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
  color: ${theme.primaryColor};
  margin-bottom: 10px;
  min-height: 64px;
`;

const CardAuthor = styled.p<any>`
  font-family: ${theme.contentFontFamily};
  font-size: 14px;
  line-height: 18px;
  color: ${theme.primaryColor};
  margin-bottom: 20px;
`;

const CardSummary = styled.p<any>`
  font-family: ${theme.headerFontFamily};
  font-size: 18px;
  line-height: 23px;
  color: ${theme.primaryColor};
  margin-bottom: 20px;
`;

const CardCTA = styled.span<any>`
  font-family: ${theme.contentFontFamily};
  font-size: 18px;
  line-height: 18px;
  color: ${theme.secondaryColor};
  text-transform: uppercase;
  text-decoration: none;
  position: relative;

  &:after {
    display: block;
    content: " ";
    height: 1px;
    width: 92px;
    background-color: ${theme.borderColor};
    margin-top: 32px;
  }
`;

export default function BlogCard(props: IBlogDetail) : JSX.Element {
  
  const {
    title,
    slug,
    creator,
    pubDate,
  } = props;
  
  // find the first img tag from the blog content
  let imgSrc = "";
  const imgTagString = props.content.encoded.match(/<img([\w\W]+?)\/>/);
  if (imgTagString && imgTagString.length > 0) {
    const matches = imgTagString[0].match("src=['\"](.*)['\"]");
    if (matches && matches.length > 1) {
      imgSrc = matches[1];
    }
  }

  const titleTruncated = title.length > 48 ? `${title.substring(0, 48)}...` : title;

  return (
    <CardContainer>
      <CardLink to={`/blog/${slug}`}>
        <CardImage src={imgSrc} />
        <CardTitle>{titleTruncated}</CardTitle>
        <CardAuthor>{creator}</CardAuthor>
        <CardSummary>
          {props.content.encodedSnippet.substring(0, 130)}...
        </CardSummary>
        <CardCTA to={`/blog/${slug}`}>
          Read Article
        </CardCTA>
      </CardLink>
    </CardContainer>
  );
}