import React from "react";
import propTypes from "prop-types";
import styled from "@emotion/styled";

import Container from "../container";
import BlogCard from "./BlogCard";
import { IBlogDetail } from "../../utils/types";

interface IBlogList {
  nodes: Array<IBlogDetail>,
  // optional?: string
}

const BlogListContainer = styled.div<any>`
  margin-top: 60px;
`;

const CardContainer = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  
  &:after {
    content: " ";
    max-width: 360px;
    width: 30%;
  }
`;

export default function BlogList(props : IBlogList) : JSX.Element {
  
  const { nodes } = props;

  return (
    <BlogListContainer>
      <Container>
        <CardContainer>
          {nodes.map((node) => <BlogCard key={node.title.toLowerCase().replace(" ", "-")} {...node} />)}
        </CardContainer>
      </Container>
    </BlogListContainer>
  );
}